/* eslint-disable react/no-unused-state */
import React, { Component } from "react"
import * as styles from "./PagesLayout.module.less"
import dayjs from "dayjs"
import { OutlinedInput as Input, Button, Typography, Grid } from "@mui/material"
import { styled } from "@mui/system"

const utc = require("dayjs/plugin/utc")
const timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

const CustomInput = styled(Input)({
  width: `100%`,
  margin: 0,
  height: `36px`,
  "&.Mui-focused fieldset": {
    borderColor: `#b7b7b7 !important`
  }
})


class HexTimestampToHumanReadableView extends Component {
  componentDidMount() {
    this.setState({ timestampValue: (dayjs().valueOf()).toString(16) })
    this.handleTimestampToDateConversion()
  }

  getValidDiffTimeStr({ diffTime, type, past }) {
    const tempDiffTime = Math.abs(diffTime)
    let extraStr = ""
    if (Number(tempDiffTime) === 0) {
      return false
    } else if (Number(tempDiffTime) > 1) {
      extraStr = "s"
    }
    const preFix = past ? "" : "In "
    const postFix = past ? " Ago" : ""
    return `${preFix}${tempDiffTime} ${type}${extraStr}${postFix}`
  }

  getRelativeTimeStr(timestampValue) {
    const tempTimestampValue = Math.round(timestampValue)
    const timeDiff = dayjs().valueOf() - tempTimestampValue
    const past = timeDiff >= 0
    const years = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "years"),
      type: "year",
      past
    })
    const months = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "months"),
      type: "month",
      past
    })
    const days = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "days"),
      type: "day",
      past
    })
    const hours = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "hours"),
      type: "hour",
      past
    })
    const minutes = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "minutes"),
      type: "minute",
      past
    })
    const diffTimeStr =
      years || months || days || hours || minutes || "A few seconds ago"
    return diffTimeStr
  }

  handleTimestampToDateConversion = () => {
    let { timestampValue } = this.state || {}
    timestampValue = parseInt(timestampValue, 16)
    timestampValue = timestampValue * 10 * 0.1
    let units = "milliseconds"
    if (timestampValue && String(timestampValue).length > 14) {
      timestampValue /= 1000
      units = "microseconds (1/1,000,000 second)"
    } else if (timestampValue && String(timestampValue).length <= 11) {
      timestampValue *= 1000
      units = "seconds"
    }
    timestampValue *= 1
    const { timeZone } = new Intl.DateTimeFormat().resolvedOptions()
    const GMTTimeStr = dayjs(timestampValue)
      .tz("GMT")
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const currentTimeStr = dayjs(timestampValue)
      .tz(timeZone)
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const year = dayjs(timestampValue).tz(timeZone).format("YYYY")
    const month = dayjs(timestampValue).tz(timeZone).format("MM")
    const day = dayjs(timestampValue).tz(timeZone).format("DD")
    const hour = dayjs(timestampValue).tz(timeZone).format("hh")
    const minutes = dayjs(timestampValue).tz(timeZone).format("mm")
    const seconds = dayjs(timestampValue).tz(timeZone).format("ss")
    const timeType = dayjs(timestampValue).tz(timeZone).format("A")
    const finalTimeZone = "LTZ"
    const diffTimeStr = this.getRelativeTimeStr(timestampValue)
    this.setState({
      GMTTimeStr,
      currentTimeStr,
      units,
      displayTtoD: true,
      year,
      month,
      day,
      hour,
      minutes,
      seconds,
      timeType,
      timeZone: finalTimeZone,
      diffTimeStr
    })
  }

  onTimestampChange = e => {
    let timestamp = e.target.value
    timestamp = timestamp
      .split(" ")
      .join("")
    this.setState({ timestampValue: timestamp })
  }

  render() {
    const {
      timestampValue = "",
      GMTTimeStr = "",
      currentTimeStr = "",
      displayTtoD = false,
      units,
      diffTimeStr
    } = this.state || {}
    return (
      <div id={"timestampToHumanReadableConverter"} className={styles.timestampToHumanReadableConverterContainer}>
        <Grid container style={{ marginTop: "50px" }} spacing={3}>
          <Grid item>
            <Typography variant={`h2`}
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          fontFamily: "Karla, sans-serif"
                        }}
            >
              Convert Hex timestamp to human-readable date and
              vice versa
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <CustomInput
              aria-label={"timestamp"}
              value={timestampValue.toString(16)}
              onChange={this.onTimestampChange}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} xl={6}>
            <Button
              className={styles.btnLayouts}
              type="primary"
              onClick={this.handleTimestampToDateConversion}
            >
              Hex Timestamp To Human-Readable
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item
                style={{ color: "#b7b7b7", textAlign: "justify", fontSize: "15px" }}
          >
            Supports Epoch timestamps in seconds, milliseconds, microseconds and
            nanoseconds.
          </Grid>
        </Grid>
        <div
          style={{
            display:
              displayTtoD && GMTTimeStr !== "Invalid Date" ? "block" : "none"
          }}
        >
          <Grid container style={{ marginTop: "25px" }} spacing={1}>
            <Grid item>
              <p>
                Assuming that this timestamp is in <b>{units}</b>:
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }} spacing={1}>
            <Grid item md={2}>
              <p>
                <b>GMT </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{GMTTimeStr}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }} spacing={1}>
            <Grid item md={2}>
              <p>
                <b>Current Time Zone </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{currentTimeStr}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }} spacing={1}>
            <Grid item md={2}>
              <p>
                <b>Timestamp </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{parseInt(timestampValue, 16)}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }} spacing={1}>
            <Grid item md={2}>
              <p>
                <b>Relative </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{diffTimeStr}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default HexTimestampToHumanReadableView
